import SC from 'styled-components';
import { Container } from 'styled-bootstrap-grid';

export const Wrapper = SC(Container)`
  @media (min-width: 1200px) {
    max-width: 1187px;
  }
  @media (max-width: 1024px) {
    max-width: 960px;
  }
  @media (max-width: 986px) {
    max-width: 890px;
  }
  @media (max-width: 914px) {
    max-width: 840px;
  }
  @media (max-width: 864px) {
    max-width: 800px;
  }
`;
