import { css } from 'styled-components';

export const gridTheme = {
  breakpoints: {
    giant: 1200,
    xl: 1200,
    desktop: 1024,
    lg: 1024,
    tablet: 768,
    md: 768,
    phone: 576,
    sm: 576,
    smaller: 575,
    xs: 575,
  },
  row: {
    padding: 10,
  },
  col: {
    padding: 10,
  },
  container: {
    padding: 5,
  },
};

export const color = {
  backgroundGray: '#363636',
  backgroundBlack: '#262424',
  backgroundBlackTransparent: 'rgba(38, 36, 36, .8)',

  primary: '#007BFF',
  primaryTransparent: 'rgba(0, 123, 255, .8)',
  white: '#FFF',
  black: '#000',

  textBlack: '#121212',

  grayLight: '#A7A7A7',
  gray: '#707070',
  gray2: '#b3b3b3',
  grayDark: '#535353',

  red: '#DE363C',
  redTransparent: 'rgba(222, 54, 60, .8)',
  orange: '#FFA500',
  green: '#5CB85C',
  greenTransparent: 'rgba(92, 184, 92, .8)',
  blueLight: '#5BC0DE',

  shadowBlack: 'rgba(0, 0, 0, .3)',
  shadowBlackTransparent: 'rgba(0, 0, 0, .16)',
  shadowWhiteTransparent: 'rgba(232, 232, 232, .34)',
  shadowOrangeTransparent: 'rgba(255, 165, 0, .16)',
  shadowPrimaryTransparent: 'rgba(12, 112, 221, .2)',
  shadowPrimary: 'rgba(12, 112, 221, .3)',
  shadowPrimaryStrong: 'rgba(12, 112, 221, .5)',

  chartBlack: 'rgba(38, 36, 36, .5)',
  chartWhite: 'rgba(255, 255, 255, .75)',
};

export const screenSizes = {
  mobile: 768,
  tablet: 1024,
  smallComputer: 1200,
  computer: 1440,
  desktop: 1920,
};

export const makeMedia = (from, to) => (...args) =>
  css`@media screen${from ? ` and (min-width: ${from}px)` : ''}${
    to ? ` and (max-width: ${to - 1}px)` : ''
  } {${css(...args)}}`;

export const media = {
  mobile: makeMedia(null, screenSizes.mobile),
  tablet: makeMedia(null, screenSizes.tablet),
  computer: makeMedia(null, screenSizes.computer),
  smallComputer: makeMedia(null, screenSizes.smallComputer),
  desktop: makeMedia(null, screenSizes.desktop),
  largescreen: makeMedia(screenSizes.desktop),
  tabletOnly: makeMedia(screenSizes.mobile, screenSizes.tablet),
  computerOnly: makeMedia(screenSizes.tablet, screenSizes.computer),
  desktopOnly: makeMedia(screenSizes.computer, screenSizes.desktop),
  aboveMobile: makeMedia(screenSizes.mobile),
  aboveTablet: makeMedia(screenSizes.tablet),
  aboveComputer: makeMedia(screenSizes.computer),
};

export const sizes = {
  borderRadius: '30px',
};

export const selectWeight = type => {
  switch (type) {
    case 'bolder': {
      return {
        font: 'RobotoBlack',
      };
    }
    case 'bold': {
      return {
        font: 'RobotoBold',
      };
    }
    case 'medium': {
      return {
        font: 'RobotoMedium',
      };
    }
    default: {
      return {
        font: 'RobotoRegular',
      };
    }
  }
};
