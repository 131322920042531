import { handleActions } from 'redux-actions';
import { FETCH_PENDING, FETCH_FAILED, FETCH_SUCCESSED } from './actions';

const initialState = {
  rates: null,
  pending: false,
  error: null,
};

export default handleActions(
  {
    [FETCH_PENDING]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [FETCH_SUCCESSED]: (state, { payload }) => ({
      ...state,
      rates: payload,
      loading: false,
      error: null,
    }),
    [FETCH_FAILED]: state => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  initialState
);
