import React, { Fragment } from 'react';
import { StyledLoader } from './styles';

const Loader = ({
  isVisible,
  margin,
  width,
  height,
  shade,
  inline,
  weight,
  className,
}) =>
  isVisible ? (
    <StyledLoader
      margin={margin}
      width={width}
      height={height}
      shade={shade}
      inline={inline}
      className={className}
      data-name="loader"
    >
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={weight}
          strokeMiterlimit="10"
        />
      </svg>
    </StyledLoader>
  ) : (
    <Fragment />
  );

Loader.styledComponent = StyledLoader;

Loader.defaultProps = {
  isVisible: true,
  margin: '50px auto',
  width: '80px',
  height: '80px',
  shade: 'primary',
  className: '',
  inline: false,
  weight: 3,
};

export default Loader;
