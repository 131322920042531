import React from 'react';
import routes from 'routes';
import { Redirect, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { renderRoutes } from 'utils/router';

const history = createBrowserHistory();
const renderedRoutes = renderRoutes(routes);

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        {renderedRoutes}
        <Redirect exact to="/" />
      </Switch>
    </Router>
  );
};

export default App;
