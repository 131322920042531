import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { reducer as pools } from 'modules/pool/store'
import { reducer as rates } from 'ducks/rates'
import { reducer as coins } from 'ducks/coins'

export default history =>
  combineReducers({
    router: connectRouter(history),
    pools,
    rates,
    coins,
  });
