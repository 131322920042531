import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ru from './dictionaries/ru.json';
import en from './dictionaries/en.json';

const defaultLng = 'en';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      ru: ru.ru,
      en: en.en,
    },
    fallbackLng: defaultLng,
    // debug: true,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
