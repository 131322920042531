import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const router = routerMiddleware(history);

const middlewares = applyMiddleware(thunk, router);

export default middlewares;
