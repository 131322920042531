import styled from 'styled-components';
import { color } from 'ui/theme';

export const Button = styled.span`
  position: relative;
  padding: 20px 29px;
  font-size: 18px;
  border: 1px solid transparent;
  border-radius: 84px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover,
  &[data-active='active'] {
    background-color: ${color.backgroundBlack};
    border-color: ${color.primary};
    box-shadow: 0 10px 20px ${color.shadowPrimaryTransparent};

    i {
      text-shadow: 0 5px 10px ${color.shadowPrimary};
      color: ${color.primary};
    }
    span {
      color: ${color.white};
    }
    [data-name='number'] {
      background-color: ${color.primary};
      box-shadow: 0 5px 10px ${color.shadowPrimaryTransparent};
    }
  }
  i {
    color: ${color.white};
    transition: all 0.25s ease;
  }
  span {
    margin-left: 8px;
    color: ${color.grayLight};
    font-size: 18px;
    font-family: 'RobotoMedium', sans-serif;
    transition: all 0.25s ease;
  }
  [data-name='number'] {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 23px;
    display: flex;
    align-items: center;
    padding: 0 14px;

    border-radius: 20px;
    font-size: 13px;
    background-color: ${color.backgroundBlack};
    box-shadow: 0 5px 10px ${color.shadowBlackTransparent};

    transition: all 0.25s ease;
    transform: translate(-50%, 50%);
  }
`;
