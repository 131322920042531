import React from 'react';
import { Route } from 'react-router-dom';

export const addIndexPath = path => `(${path}|/)`;

export const renderRoutes = routes =>
  routes.map(({ path, component, defaultRoute, exact, ...rest }) => {
    if (rest.public) {
      return (
        <Route
          key={path}
          component={component}
          path={defaultRoute ? addIndexPath(path) : path}
          exact={exact}
        />
      );
    }

    return (
      <Route
        key={path}
        component={component}
        path={defaultRoute ? addIndexPath(path) : path}
        exact={exact}
      />
    );
  });
