import React from "react";
import { Wrapper } from "./styles";

const HeaderContainer = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default HeaderContainer;
