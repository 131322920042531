import { handleActions } from 'redux-actions';
import {
  FETCH_ACCOUNT_SUCCESSED,
  FETCH_PENDING,
  FETCH_SUCCESSED,
  FETCH_BLOCKS_SUCCESSED,
  FETCH_PAYMENTS_SUCCESSED,
  FETCH_MINERS_SUCCESSED,
  FETCH_ACCOUNT_FAILED,
  FETCH_COINS_SUCCESSED,
  FETCH_SINGLE_SUCCESSED,
  FETCH_TOTAL_AMOUNT_SUCCESSED, POST_SETTINGS_FAILED, POST_SETTINGS_SUCCESSED, UPDATE_SETTINGS, SETTINGS_SET_FOCUS,
} from './actions';

const initialState = {
  list: [],
  data: null,
  pending: false,
  error: null,
  blocks: null,
  payments: null,
  miners: null,
  account: null,
  accountError: null,
  coins: null,
  totalPayments: null,

  isSettingsErrorInternal: false,
  hasSettingsError: false,
  settingsError: null,
  settingsErrorInfo: '',
  isSettingsSucceed: false,

  settings: {
    focused: -1,
    threshold: {
      ip: '',
      amount: 0
    }
  }
};

export default handleActions(
  {
    [FETCH_PENDING]: state => ({
      ...state,
      pending: true,
      error: null,
      accountError: false,
    }),
    [FETCH_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      list: payload,
      error: null,
    }),
    [FETCH_SINGLE_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      data: payload,
      error: null,
    }),
    [FETCH_COINS_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      coins: payload.coins,
      error: null,
    }),
    [FETCH_TOTAL_AMOUNT_SUCCESSED]: (state, { payload }) => ({
      ...state,
      totalPayments: payload,
    }),
    [FETCH_BLOCKS_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      blocks: payload,
      error: null,
    }),
    [FETCH_PAYMENTS_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      payments: payload,
      error: null,
    }),
    [FETCH_MINERS_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      miners: payload,
      error: null,
    }),
    [FETCH_ACCOUNT_FAILED]: state => ({
      ...state,
      pending: false,
      miners: null,
      accountError: true,
    }),
    [FETCH_ACCOUNT_SUCCESSED]: (state, { payload }) => {
      let nState = {
        ...state,
        pending: false,
        account: payload,
        accountError: false,
        error: null,
      };
      if (!nState.settings.threshold.amount && payload) {
        nState.settings.threshold.amount = payload.payoutThreshold;
      }
      return nState;
    },
    [POST_SETTINGS_FAILED]: (state, { payload }) => ({
      ...state,
      pending: payload.isInternal ? state.pending : false,
      hasSettingsError: true,
      isSettingsErrorInternal: Boolean(payload.isInternal),
      settingsError: payload.error || "ERROR_UNKNOWN",
      settingsErrorInfo: payload.info || '',
      isSettingsSucceed: false
    }),
    [POST_SETTINGS_SUCCESSED]: (state, { payload }) => ({
      ...state,
      pending: false,
      settingsError: null,
      hasSettingsError: false,
      isSettingsErrorInternal: false,
      settingsErrorInfo: '',
      isSettingsSucceed: !(payload && payload.reset)
    }),
    [UPDATE_SETTINGS]: (state, { payload }) => ({
      ...state,
      settings: {
        ...payload
      }
    }),
    [SETTINGS_SET_FOCUS]: ({ settings, ...state }, { payload }) => ({
      ...state,
      settings: {
        ...settings,
        focused: payload
      }
    })
  },
  initialState
);
