import React from 'react';
import { Button } from './styles';

const TabButton = ({ icon, value, active, children, ...rest }) => {
  return (
    <Button data-active={active && 'active'} {...rest}>
      <i className={icon}></i>
      <span>{children}</span>
      {(value || value === 0) && <div data-name="number">{value}</div>}
    </Button>
  );
};

TabButton.styled = Button;

TabButton.defaultProps = {
  icon: 'far fa-cubes',
  value: null,
  active: false,
};

export default TabButton;
