import { modelName } from './actions';

export const getList = state => state[modelName].list;
export const getCoins = state => state[modelName].coins;
export const getError = state => state[modelName].error;
export const getAccountError = state => state[modelName].accountError;
export const getBlocks = state => state[modelName].blocks;
export const getPayments = state => state[modelName].payments;
export const getMiners = state => state[modelName].miners;
export const getData = state => state[modelName].data;
export const getPending = state => state[modelName].pending;
export const getAccount = state => state[modelName].account;
export const getTotalPayments = state => state[modelName].totalPayments;
export const getSettingsError = state => ({
  hasError: state[modelName].hasSettingsError,
  error: state[modelName].settingsError,
  isInternal: state[modelName].isSettingsErrorInternal,
  info: state[modelName].settingsErrorInfo,
  isSucceed: state[modelName].isSettingsSucceed
});
export const getSettings= state => state[modelName].settings;