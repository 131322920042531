import { Ethereum } from 'ui/outlines';

export class CoinService {
  static COINS = [
    {
      name: 'Ethereum',
      slug: 'eth',
      url: 'https://pool.pirl.network',
      image: Ethereum,
    },
  ];

  static getCoin(slug) {
    return this.COINS.find(coin => coin.slug === slug);
  }
}
