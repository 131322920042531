export const modelName = 'coins';

export const FETCH_PENDING = `${modelName}/FETCH_PENDING`;
export const FETCH_FAILED = `${modelName}/FETCH_FAILED`;
export const FETCH_SUCCESSED = `${modelName}/FETCH_SUCCESSED`;

export const FETCH_SINGLE_SUCCESSED = `${modelName}/FETCH_SINGLE_SUCCESSED`;
export const FETCH_COINS_SUCCESSED = `${modelName}/FETCH_COINS_SUCCESSED`;

export const FETCH_BLOCKS_SUCCESSED = `${modelName}/FETCH_BLOCKS_SUCCESSED`;
export const FETCH_PAYMENTS_SUCCESSED = `${modelName}/FETCH_PAYMENTS_SUCCESSED`;
export const FETCH_MINERS_SUCCESSED = `${modelName}/FETCH_MINERS_SUCCESSED`;

export const FETCH_ACCOUNT_SUCCESSED = `${modelName}/FETCH_ACCOUNT_SUCCESSED`;
export const FETCH_ACCOUNT_FAILED = `${modelName}/FETCH_ACCOUNT_FAILED`;

export const FETCH_TOTAL_AMOUNT_SUCCESSED = `${modelName}/FETCH_TOTAL_AMOUNT_SUCCESSED`;

export const POST_SETTINGS_FAILED = `${modelName}/POST_SETTINGS_FAILED`
export const POST_SETTINGS_SUCCESSED = `${modelName}/POST_SETTINGS_SUCCESSED`
export const UPDATE_SETTINGS = `${modelName}/UPDATE_SETTINGS`

export const SETTINGS_SET_FOCUS = `${modelName}/SETTINGS_SET_FOCUS`;
