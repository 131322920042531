import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from './serviceWorker';
import './index.scss';
import moment from 'moment';
import "moment/locale/ru"

moment.updateLocale('en', {relativeTime: {
  future: (diff) => diff === 'just now' ? diff : `in ${diff}`,
  past: (diff) => diff === 'just now' ? diff : `${diff} ago`,
  s: 'just now',
}});
moment.updateLocale('ru', {relativeTime: {
  future: (diff) => diff === 'только что' ? diff : `через ${diff}`,
  past: (diff) => diff === 'только что' ? diff : `${diff} назад`,
  s: 'только что',
}});
moment.relativeTimeThreshold('ss', 1)

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
