import React, {
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { removeNullElements } from 'utils/arrays'
import { TableWrapper, HeadRow, HeadText, BodyRow } from './styles';

const Table = ({ columns, data, hover, onClick }) => {
  const notEmptyColumns = useMemo(() => removeNullElements(columns), [columns])
  return (
    <TableWrapper>
      <thead>
        <HeadRow>
          {notEmptyColumns.map((c, id) => (
            <HeadText key={id}>
              <span
                data-wrap="cell"
                dangerouslySetInnerHTML={{ __html: c.label }}
              ></span>
            </HeadText>
          ))}
        </HeadRow>
      </thead>
      <tbody>
        {data.map((d, id) => (
          <BodyRow
            key={id}
            onClick={() => onClick(d.id)}
            className={hover && 'hover'}
          >
            {notEmptyColumns.map((c, c_id) => (
              <td key={c_id}>
                <div
                  data-label={c.label.replace(/<\/?[^>]+(>|$)/g, '')}
                  data-wrap="cell"
                  className={
                    c.className
                      ? c.className instanceof Function
                        ? c.className(d)
                        : c.className
                      : ''
                  }
                >
                  {c.href ? (
                    c.external ? (
                      <a
                        href={c.href(d)}
                        {...(c.follow
                          ? {}
                          : { rel: 'noopener noreferrer', target: '_blank' })}
                        className={c.className === 'blue' ? 'blue' : ''}
                      >
                        {d[c.accessor]}
                      </a>
                    ) : (
                      <Link
                        to={c.href(d)}
                        className={c.className === 'blue' ? 'blue' : ''}
                      >
                        {d[c.accessor]}
                      </Link>
                    )
                  ) : (
                    <div className={c.className === 'blue' ? 'blue' : ''}>
                      {d[c.accessor]}
                    </div>
                  )}
                </div>
              </td>
            ))}
          </BodyRow>
        ))}
      </tbody>
    </TableWrapper>
  );
};

Table.defaultProps = {
  columns: [],
  data: [],
  hover: false,
  onClick: () => {},
};

export default Table;
