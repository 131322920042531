import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import reducers from './reducers';
import middlewares, { history } from './middlewares';

let store = null;

store = createStore(reducers(history), composeWithDevTools(middlewares));

export { store, history, connect, bindActionCreators };
