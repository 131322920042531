import React from 'react';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { Provider } from 'react-redux';
import { store, history } from 'store';
import { ConnectedRouter } from 'connected-react-router';
import { gridTheme } from 'ui/theme';
import i18n from 'i18n';

const Root = () => {
  return (
    <Provider store={store}>
      <GridThemeProvider gridTheme={gridTheme}>
        <I18nextProvider i18n={i18n}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </I18nextProvider>
      </GridThemeProvider>
    </Provider>
  );
};

export default Root;
