import { createAction } from 'redux-actions';
import { ApiService } from 'services';
import { FETCH_PENDING, FETCH_SUCCESSED, FETCH_FAILED } from './actions';

const fetchPending = createAction(FETCH_PENDING);
const fetchFailed = createAction(FETCH_FAILED);
const fetchSuccessed = createAction(FETCH_SUCCESSED);

export const fetchRates = () => async dispatch => {
  dispatch(fetchPending());

  try {
    const res = await ApiService.get('/api/rates');

    dispatch(fetchSuccessed(res.rates));
  } catch (error) {
    dispatch(fetchFailed());
  }
};
