import { isString } from 'utils/string';

export class ApiService {
  static options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  static awaitRequestEnabled = false;

  static awaitRequest = Promise.resolve();

  static request(method, url, options, settings) {
    const fullOptions = Object.assign(
      {
        method,
      },
      this.options,
      options
    );

    if (this.awaitRequestEnabled) {
      return this.awaitRequest.then(() => {
        this.awaitRequestEnabled = false;
        this.awaitRequest = Promise.resolve();
        const updateOptions = this.formatBody(options.body);
        const updateBody = Object.assign(
          {
            method,
          },
          this.options,
          updateOptions
        );

        return this.makeRequest(url, updateBody, settings);
      });
    }

    return this.makeRequest(url, fullOptions, settings);
  }

  /**
   * @param {string} url
   * @param {object} [options]
   * @return {Promise<T>}
   */
  static get(url, options, settings = {}) {
    return this.request('GET', url, options, settings);
  }

  /**
   * @param {string} url
   * @return {Promise<T>}
   */
  static post(url, body, options) {
    const fullOptions = this.formatBody(body, options);

    return this.request('POST', url, fullOptions);
  }

  /**
   * @param {string} url
   * @return {Promise<T>}
   */
  static put(url, body, options) {
    const fullOptions = this.formatBody(body, options);

    return this.request('PUT', url, fullOptions);
  }

  /**
   * @param {string} url
   * @return {Promise<T>}
   */
  static patch(url, body, options) {
    const fullOptions = this.formatBody(body, options);

    return this.request('PATCH', url, fullOptions);
  }

  /**
   * @param {string} url
   * @return {Promise<T>}
   */
  static delete(url, body, options) {
    const fullOptions = this.formatBody(body, options);

    return this.request('DELETE', url, fullOptions);
  }

  /**
   * @param {object} [body]
   * @param {object} [options]
   * @return {object}
   */
  static formatBody(body, options = null) {
    if (options && options.isBinary) {
      return Object.assign(
        {},
        {
          body,
        },
        options
      );
    }

    if (isString(body)) {
      const parseBody = JSON.parse(body);

      return this.makeBody(parseBody, options);
    }

    return this.makeBody(body, options);
  }

  /**
   * @param {string} url
   * @param {object} [options]
   * @return {Promise<T>}
   */
  static makeRequest(url, options, settings = {}) {
    return fetch(url, options).then(response => {
      if (response.status === 200) {
        return settings.hasOwnProperty('json') ? response : response.json();
      }

      if (!response.ok) {
        throw response;
      }

      return response;
    });
  }

  /**
   * @param {object} [body]
   * @param {object} [options]
   * @return {object}
   */
  static makeBody(body, options) {
    return Object.assign(
      {},
      {
        body: JSON.stringify(body),
      },
      options
    );
  }
}
